var __jsx = React.createElement;
import React from 'react';
import Image from '../../../atoms/Image';
import Anchor from '../../../atoms/Anchor';
var ImageContent = function ImageContent(_ref) {
  var imageContentCollection = _ref.imageContentCollection;
  return __jsx("div", {
    className: "nva-footer__image-content"
  }, __jsx("div", {
    className: "nva-footer__image-content-inner"
  }, imageContentCollection && (imageContentCollection === null || imageContentCollection === void 0 ? void 0 : imageContentCollection.map(function (imageItem, index) {
    var _imageItem$imageLink, _imageItem$imageLink2, _imageItem$image, _imageItem$image2, _imageItem$image3, _imageItem$image4, _imageItem$image5, _imageItem$image6, _imageItem$image7, _imageItem$image8;
    return imageItem.imageLink ? __jsx(Anchor, {
      key: index,
      className: "nva-footer__image-link",
      to: imageItem === null || imageItem === void 0 ? void 0 : (_imageItem$imageLink = imageItem.imageLink) === null || _imageItem$imageLink === void 0 ? void 0 : _imageItem$imageLink.url,
      ctaBehavior: imageItem === null || imageItem === void 0 ? void 0 : (_imageItem$imageLink2 = imageItem.imageLink) === null || _imageItem$imageLink2 === void 0 ? void 0 : _imageItem$imageLink2.behavior
    }, __jsx(Image, {
      className: "nva-footer__image",
      width: imageItem === null || imageItem === void 0 ? void 0 : (_imageItem$image = imageItem.image) === null || _imageItem$image === void 0 ? void 0 : _imageItem$image.width,
      height: imageItem === null || imageItem === void 0 ? void 0 : (_imageItem$image2 = imageItem.image) === null || _imageItem$image2 === void 0 ? void 0 : _imageItem$image2.height,
      alt: imageItem === null || imageItem === void 0 ? void 0 : imageItem.altText,
      title: imageItem === null || imageItem === void 0 ? void 0 : imageItem.title,
      src: imageItem === null || imageItem === void 0 ? void 0 : (_imageItem$image3 = imageItem.image) === null || _imageItem$image3 === void 0 ? void 0 : _imageItem$image3.url,
      contentType: imageItem === null || imageItem === void 0 ? void 0 : (_imageItem$image4 = imageItem.image) === null || _imageItem$image4 === void 0 ? void 0 : _imageItem$image4.contentType
    })) : __jsx(Image, {
      key: index,
      className: "nva-footer__image",
      width: imageItem === null || imageItem === void 0 ? void 0 : (_imageItem$image5 = imageItem.image) === null || _imageItem$image5 === void 0 ? void 0 : _imageItem$image5.width,
      height: imageItem === null || imageItem === void 0 ? void 0 : (_imageItem$image6 = imageItem.image) === null || _imageItem$image6 === void 0 ? void 0 : _imageItem$image6.height,
      alt: imageItem === null || imageItem === void 0 ? void 0 : imageItem.altText,
      title: imageItem === null || imageItem === void 0 ? void 0 : imageItem.title,
      src: imageItem === null || imageItem === void 0 ? void 0 : (_imageItem$image7 = imageItem.image) === null || _imageItem$image7 === void 0 ? void 0 : _imageItem$image7.url,
      contentType: imageItem === null || imageItem === void 0 ? void 0 : (_imageItem$image8 = imageItem.image) === null || _imageItem$image8 === void 0 ? void 0 : _imageItem$image8.contentType
    });
  }))));
};
export default ImageContent;