import { css } from 'styled-components';
var bannerColors = {
  undefined: {
    textDecoration: 'underline',
    bannerbackgroundColor: 'rgb(235, 0, 0)',
    anchorColor: 'rgb(255, 255, 255)',
    anchorHoverColor: 'rgb(255, 255, 255)',
    textColor: 'rgb(255, 255, 255)'
  },
  "null": {
    textDecoration: 'underline',
    bannerbackgroundColor: 'rgb(235, 0, 0)',
    anchorColor: 'rgb(255, 255, 255)',
    anchorHoverColor: 'rgb(255, 255, 255)',
    textColor: 'rgb(255, 255, 255)'
  },
  'Theme Color': {
    textDecoration: 'underline',
    bannerbackgroundColor: 'rgb(235, 0, 0)',
    anchorColor: 'rgb(255, 255, 255)',
    anchorHoverColor: 'rgb(255, 255, 255)',
    textColor: 'rgb(255, 255, 255)'
  },
  Grey: {
    textDecoration: 'underline',
    bannerbackgroundColor: 'rgb(118, 118, 118)',
    anchorColor: 'rgb(255, 255, 255)',
    anchorHoverColor: 'rgb(255, 255, 255)',
    textColor: 'rgb(255, 255, 255)'
  },
  Red: {
    textDecoration: 'underline',
    bannerbackgroundColor: 'rgb(235, 0, 0)',
    anchorColor: 'rgb(255, 255, 255)',
    anchorHoverColor: 'rgb(255, 255, 255)',
    textColor: 'rgb(255, 255, 255)'
  }
};
var styles = css(["", " .banner-div{background-color:", ";padding:15px;text-align:center;color:rgb(255,255,255);height:78px;display:", ";a{text-align:center;}a:not(.read-more){text-decoration:", ";color:", " !important;font:inherit;letter-spacing:inherit;line-height:inherit;&:hover{color:", ";text-decoration:underline;}}&.red{background-color:rgb(235,0,0);a{text-decoration:underline;color:rgb(255,255,255);&:hover{color:rgb(255,255,255);text-decoration:underline;}}}&.grey{background-color:rgb(118,118,118);a{text-decoration:underline;color:rgb(255,255,255);&:hover{color:rgb(255,255,255);text-decoration:underline;}}}&.theme_color{background-color:", ";a{text-decoration:none;color:", ";&:hover{color:", ";text-decoration:underline;}}}.read-more{color:rgb(255,255,255) !important;text-decoration:underline;&:hover{color:rgb(255,255,255);text-decoration:underline;}}p{color:", ";margin:0;display:inline;}@supports (-webkit-line-clamp:2){.para-collapse{overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;}}@media (min-width:1024px){p{font-size:18px;line-height:28px;letter-spacing:0.18px;}}}"], function (props) {
  var _props$theme, _props$theme2;
  if ((_props$theme = props.theme) !== null && _props$theme !== void 0 && _props$theme.anchor && (_props$theme2 = props.theme) !== null && _props$theme2 !== void 0 && _props$theme2.button && props.backgroundColor === 'Theme Color') {
    var _props$theme3, _props$theme3$button, _props$theme4, _props$theme5;
    var themeStylingColors = {
      textDecoration: 'underline',
      bannerbackgroundColor: "var(--sc-button--bg-color-hero, ".concat(((_props$theme3 = props.theme) === null || _props$theme3 === void 0 ? void 0 : (_props$theme3$button = _props$theme3.button) === null || _props$theme3$button === void 0 ? void 0 : _props$theme3$button.backgroundColorHero) || 'rgb(235, 0, 0)', ")"),
      anchorColor: ((_props$theme4 = props.theme) === null || _props$theme4 === void 0 ? void 0 : _props$theme4.anchor.anchorLink) || 'rgb(255, 255, 255)',
      anchorHoverColor: ((_props$theme5 = props.theme) === null || _props$theme5 === void 0 ? void 0 : _props$theme5.anchor.anchorLink) || 'rgb(255, 255, 255)',
      textColor: 'rgb(255, 255, 255)'
    };
    bannerColors['Theme Color'] = themeStylingColors;
  }
}, function (props) {
  var _bannerColors$props$b;
  return props && ((_bannerColors$props$b = bannerColors[props.backgroundColor]) === null || _bannerColors$props$b === void 0 ? void 0 : _bannerColors$props$b.bannerbackgroundColor) || 'rgb(235, 0, 0)';
}, function (props) {
  return props && (props.showBanner === false ? 'none' : null);
}, function (props) {
  var _bannerColors$props$b2;
  return props && ((_bannerColors$props$b2 = bannerColors[props.backgroundColor]) === null || _bannerColors$props$b2 === void 0 ? void 0 : _bannerColors$props$b2.textDecoration) || 'underline';
}, function (props) {
  var _bannerColors$props$b3;
  return props && ((_bannerColors$props$b3 = bannerColors[props.backgroundColor]) === null || _bannerColors$props$b3 === void 0 ? void 0 : _bannerColors$props$b3.anchorColor) || 'rgb(255, 255, 255)';
}, function (props) {
  var _bannerColors$props$b4;
  return props && ((_bannerColors$props$b4 = bannerColors[props.backgroundColor]) === null || _bannerColors$props$b4 === void 0 ? void 0 : _bannerColors$props$b4.anchorHoverColor) || 'rgb(255, 255, 255)';
}, function (props) {
  var _props$theme6;
  if ((_props$theme6 = props.theme) !== null && _props$theme6 !== void 0 && _props$theme6.button) {
    var _props$theme7, _props$theme7$button;
    return "var(--sc-button--bg-color-hero, ".concat(((_props$theme7 = props.theme) === null || _props$theme7 === void 0 ? void 0 : (_props$theme7$button = _props$theme7.button) === null || _props$theme7$button === void 0 ? void 0 : _props$theme7$button.backgroundColorHero) || 'rgb(235, 0, 0)', ")");
  }
}, function (props) {
  var _props$theme8;
  if ((_props$theme8 = props.theme) !== null && _props$theme8 !== void 0 && _props$theme8.anchor) {
    var _props$theme9;
    return ((_props$theme9 = props.theme) === null || _props$theme9 === void 0 ? void 0 : _props$theme9.anchor.anchorLink) || 'rgb(255, 255, 255)';
  }
}, function (props) {
  var _props$theme10;
  if ((_props$theme10 = props.theme) !== null && _props$theme10 !== void 0 && _props$theme10.anchor) {
    var _props$theme11;
    return ((_props$theme11 = props.theme) === null || _props$theme11 === void 0 ? void 0 : _props$theme11.anchor.anchorLink) || 'rgb(255, 255, 255)';
  }
}, function (props) {
  var _bannerColors$props$b5;
  return props && ((_bannerColors$props$b5 = bannerColors[props.backgroundColor]) === null || _bannerColors$props$b5 === void 0 ? void 0 : _bannerColors$props$b5.textColor) || 'rgb(255, 255, 255)';
});
export default styles;