/**
 * useMediaQuery
 *
 * JavaScript media query hook.
 * Example:
 * const isDesktop = useMediaQuery('lg');
 *
 * @param {string} breakpoint - breakpoint value ('xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl')
 * @returns {boolean} media query match
 */

import { useState, useEffect } from 'react';
import { BREAKPOINTS } from '../themes/breakpoints';
var useMediaQuery = function useMediaQuery(breakpoint) {
  var _window;
  if (typeof window === 'undefined') return;
  var mediaQuery = (_window = window) === null || _window === void 0 ? void 0 : _window.matchMedia("(min-width: ".concat(BREAKPOINTS[breakpoint], ")"));
  var _useState = useState(mediaQuery === null || mediaQuery === void 0 ? void 0 : mediaQuery.matches),
    matchMediaQuery = _useState[0],
    setMatchMediaQuery = _useState[1];
  useEffect(function () {
    mediaQuery === null || mediaQuery === void 0 ? void 0 : mediaQuery.addEventListener('change', function () {
      setMatchMediaQuery(mediaQuery === null || mediaQuery === void 0 ? void 0 : mediaQuery.matches);
    });
  }, []);
  return matchMediaQuery;
};
export default useMediaQuery;