/**
 * @name HEADER_VAR
 * @description CSS variables for the HeaderV2 component
 */

export var HEADER_VAR = {
  bgColor: {
    "private": '--_nva-header--bg-color',
    "public": '--nva-header--bg-color',
    value: 'hsl(var(--g-color-white))'
  },
  mobileMenuBgColor: {
    "private": '--_nva-header--mobile-menu-bg-color',
    "public": '--nva-header--mobile-menu-bg-color',
    value: 'hsl(var(--g-color-white))'
  },
  fgColor: {
    "private": '--_nva-header--fg-color',
    "public": '--nva-header--fg-color',
    value: 'hsl(var(--g-color-neutral-800))'
  },
  fgColorHover: {
    "private": '--_nva-header--fg-color-hover',
    "public": '--nva-header--fg-color-hover',
    value: 'hsl(var(--g-color-neutral-1000))'
  },
  templateAreas: {
    "private": '--_nva-header--template-areas',
    "public": '--nva-header--template-areas',
    value: '"hamburger . logo logo"',
    smValue: '"hamburger . . . . . logo logo"',
    lgValue: "\"logo logo contact contact contact contact contact contact contact social social social\"\n\t\t\t\t\t\t\t\"logo logo utils utils utils utils utils utils utils utils utils utils\"\n\t\t\t\t\t\t\t\"logo logo main main main main main main cta cta cta cta\""
  },
  mobileMenuTemplateAreas: {
    "private": '--_nva-header--mobile-menu-template-areas',
    "public": '--nva-header--mobile-menu-template-areas',
    value: '"social""main""utils""contact""cta""mini-cta"'
  },
  mobileMenuMenuTopTemplateAreas: {
    "private": '--_nva-header--mobile-menu-top-template-areas',
    "public": '--nva-header--mobile-menu-top-template-areas',
    value: 'hamburger . . logo'
  },
  mobileMenuInitialX: {
    "private": '--_nva-header--mobile-menu-initial-x',
    "public": '--nva-header--mobile-menu-initial-x',
    value: '-100%'
  },
  mobileMenuInitialY: {
    "private": '--_nva-header--mobile-menu-initial-y',
    "public": '--nva-header--mobile-menu-initial-y',
    value: '0'
  },
  mobileMenuPlacement: {
    "private": '--_nva-header--mobile-menu-placement',
    "public": '--nva-header--mobile-menu-placement',
    value: 'var(--MENU-LEFT)'
  },
  dividerArea: {
    "private": '--_nva-header--divider-area',
    "public": '--nva-header--divider-area',
    value: 'divider'
  },
  dividerColor: {
    "private": '--_nva-header--divider-color',
    "public": '--nva-header--divider-color',
    value: 'hsl(var(--g-color-neutral-100))'
  },
  dividerHeight: {
    "private": '--_nva-header--divider-height',
    "public": '--nva-header--divider-height',
    value: '1px'
  },
  dividerWidth: {
    "private": '--_nva-header--divider-width',
    "public": '--nva-header--divider-width',
    value: '100%'
  },
  dividerXOffset: {
    "private": '--_nva-header--divider-x-offset',
    "public": '--nva-header--divider-x-offset',
    value: '0'
  },
  dividerYOffset: {
    "private": '--_nva-header--divider-y-offset',
    "public": '--nva-header--divider-y-offset',
    value: '0'
  },
  logoArea: {
    "private": '--_nva-header--logo-area',
    "public": '--nva-header--logo-area',
    value: 'logo'
  },
  logoPlace: {
    "private": '--_nva-header--logo-place',
    "public": '--nva-header--logo-place',
    value: 'center end'
  },
  hamburgerArea: {
    "private": '--_nva-header--hamburger-area',
    "public": '--nva-header--hamburger-area',
    value: 'hamburger'
  },
  hamburgerPlace: {
    "private": '--_nva-header--hamburger-place',
    "public": '--nva-header--hamburger-place',
    value: 'center start'
  },
  hamburgerColor: {
    "private": '--_nva-header--hamburger-color',
    "public": '--nva-header--hamburger-color',
    value: 'hsl(var(--g-color-neutral-800))'
  },
  mainMenuArea: {
    "private": '--_nva-header--main-menu-area',
    "public": '--nva-header--main-menu-area',
    value: 'main'
  },
  mainMenuPlace: {
    "private": '--_nva-header--main-menu-place',
    "public": '--nva-header--main-menu-place',
    value: 'center start'
  },
  ctaArea: {
    "private": '--_nva-header--cta-area',
    "public": '--nva-header--cta-area',
    value: 'cta'
  },
  ctaPlace: {
    "private": '--_nva-header--cta-place',
    "public": '--nva-header--cta-place',
    value: 'center end'
  },
  ctaMenuFlexWrap: {
    "private": '--_nva-header--cta-menu-flex-wrap',
    "public": '--nva-header--cta-menu-flex-wrap',
    value: 'wrap'
  },
  ctaMenuAlignment: {
    "private": '--_nva-header--cta-menu-alignment',
    "public": '--nva-header--cta-menu-alignment',
    value: 'end',
    normal: 'normal'
  },
  miniCtaArea: {
    "private": '--_nva-header--mini-cta-area',
    "public": '--nva-header--mini-cta-area',
    value: 'mini-cta'
  },
  miniCtaPlace: {
    "private": '--_nva-header--mini-cta-place',
    "public": '--nva-header--mini-cta-place',
    value: 'center center'
  },
  miniCtaAlignment: {
    "private": '--_nva-header--mini-cta-alignment',
    "public": '--nva-header--mini-cta-alignment',
    value: 'center'
  },
  utilsArea: {
    "private": '--_nva-header--utils-area',
    "public": '--nva-header--utils-area',
    value: 'utils'
  },
  utilsPlace: {
    "private": '--_nva-header--utils-place',
    "public": '--nva-header--utils-place',
    value: 'center start'
  },
  contactArea: {
    "private": '--_nva-header--contact-area',
    "public": '--nva-header--contact-area',
    value: 'contact'
  },
  contactPlace: {
    "private": '--_nva-header--contact-place',
    "public": '--nva-header--contact-place',
    value: 'center start'
  },
  contactListDirection: {
    "private": '--_nva-header--contact-list-direction',
    "public": '--nva-header--contact-list-direction',
    value: 'column',
    lgValue: 'row'
  },
  socialArea: {
    "private": '--_nva-header--social-area',
    "public": '--nva-header--social-area',
    value: 'social'
  },
  socialPlace: {
    "private": '--_nva-header--social-place',
    "public": '--nva-header--social-place',
    value: 'center end'
  },
  socialIconWrapperHeight: {
    "private": '--_nva-header--social-icon-wrapper-height',
    "public": '--nva-header--social-icon-wrapper-height',
    value: '30px'
  },
  socialIconWrapperWidth: {
    "private": '--_nva-header--social-icon-wrapper-width',
    "public": '--nva-header--social-icon-wrapper-width',
    value: '30px'
  },
  socialIconColor: {
    "private": '--_nva-header--social-icon-color',
    "public": '--nva-header--social-icon-color',
    value: 'transparent'
  },
  socialIconBorderColor: {
    "private": '--_nva-header--social-icon-border-color',
    "public": '--nva-header--social-icon-border-color',
    value: 'transparent'
  },
  socialIconFillColor: {
    "private": '--_nva-header--social-icon-fill-color',
    "public": '--nva-header--social-icon-fill-color',
    value: 'hsl(var(--g-color-neutral-800))',
    hover: 'hsl(var(--g-color-neutral-1000))'
  },
  socialIconHeight: {
    "private": '--_nva-header--social-icon-height',
    "public": '--nva-header--social-icon-height',
    value: '1rem'
  },
  socialIconWidth: {
    "private": '--_nva-header--social-icon-width',
    "public": '--nva-header--social-icon-width',
    value: '1rem'
  },
  searchArea: {
    "private": '--_nva-header--search-area',
    "public": '--nva-header--search-area',
    value: 'search'
  },
  searchPlace: {
    "private": '--_nva-header--search-place',
    "public": '--nva-header--search-place',
    value: 'center start'
  },
  stickyCtaBgColor: {
    "private": '--_nva-header--sticky-cta-bg-color',
    "public": '--nva-header--sticky-cta-bg-color',
    value: 'hsl(var(--g-color-white))'
  },
  stickyCtaBorderColor: {
    "private": '--_nva-header--sticky-cta-border-color',
    "public": '--nva-header--sticky-cta-border-color',
    value: 'hsl(var(--g-color-primary))'
  },
  stickyCtaShadow: {
    "private": '--_nva-header--sticky-cta-shadow',
    "public": '--nva-header--sticky-cta-shadow',
    value: '0 -4px 8px'
  }
};