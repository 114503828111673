import _objectWithoutProperties from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["className", "globalHeaderData", "yextContactInformationData", "siteBusinessType", "headerLogo", "showContactInfoInFooter", "isNvaMainSite", "themeName"];
var __jsx = React.createElement;
/**
 * Represents Global Header.
 * @author kkum
 */import React from 'react';
import { BUSINESS_TYPES } from '../../../constants/globalConstants';
import GeneralPracticeHeader from './GeneralPractice';
import MasterbrandHeader from './Masterbrand';
var GlobalHeader = function GlobalHeader(_ref) {
  var className = _ref.className,
    globalHeaderData = _ref.globalHeaderData,
    yextContactInformationData = _ref.yextContactInformationData,
    siteBusinessType = _ref.siteBusinessType,
    headerLogo = _ref.headerLogo,
    showContactInfoInFooter = _ref.showContactInfoInFooter,
    isNvaMainSite = _ref.isNvaMainSite,
    themeName = _ref.themeName,
    others = _objectWithoutProperties(_ref, _excluded);
  if (!globalHeaderData) {
    return null;
  }
  if (siteBusinessType === BUSINESS_TYPES.GENERAL_PRACTICE || siteBusinessType === BUSINESS_TYPES.PET_RESORTS_BARK_SIDE || siteBusinessType === BUSINESS_TYPES.PET_RESORTS_PETSUITES || siteBusinessType === BUSINESS_TYPES.SPECIALTY_ER || siteBusinessType === BUSINESS_TYPES.EQUINE) {
    return __jsx(React.Fragment, null, __jsx(GeneralPracticeHeader, {
      globalHeaderData: globalHeaderData,
      yextContactInformationData: yextContactInformationData && yextContactInformationData,
      headerLogo: headerLogo,
      siteBusinessType: siteBusinessType,
      showContactInfoInFooter: showContactInfoInFooter,
      themeName: themeName,
      isNvaMainSite: isNvaMainSite
    }));
  }
  if (siteBusinessType === BUSINESS_TYPES.CORPORATE) {
    return __jsx(React.Fragment, null, __jsx(GeneralPracticeHeader, {
      globalHeaderData: globalHeaderData,
      yextContactInformationData: yextContactInformationData && yextContactInformationData,
      headerLogo: headerLogo,
      siteBusinessType: siteBusinessType,
      showContactInfoInFooter: showContactInfoInFooter,
      themeName: themeName,
      isNvaMainSite: isNvaMainSite
    }));
  } else if (siteBusinessType === BUSINESS_TYPES.MASTER_BRAND) {
    return __jsx(React.Fragment, null, __jsx(MasterbrandHeader, {
      globalHeaderData: globalHeaderData,
      yextContactInformationData: yextContactInformationData && yextContactInformationData,
      headerLogo: headerLogo,
      siteBusinessType: siteBusinessType,
      themeName: themeName,
      isNvaMainSite: isNvaMainSite
    }));
  }
  return null;
};
GlobalHeader.defaultProps = {
  className: '',
  isNvaMainSite: false
};
export default GlobalHeader;