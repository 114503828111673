var __jsx = React.createElement;
import React, { useContext } from 'react';
import Anchor from '../../../atoms/Anchor';
import { Context } from '../../../../global-state-context';
import analyticsConstants from '../../../../constants/analyticsConstants';
var footerAnalytics = analyticsConstants.globalFooter;
var Logo = function Logo(_ref) {
  var footerLogo = _ref.footerLogo;
  var _useContext = useContext(Context),
    websiteName = _useContext.websiteName;
  return __jsx("div", {
    className: "nva-footer__logo"
  }, __jsx(Anchor, {
    title: "Logo",
    to: "/",
    "data-analytics-type": footerAnalytics.analyticsType.GLOBAL_NAV,
    "data-analytics-value": footerAnalytics.analyticsValue.GLOBAL_NAV_LOGO
  }, __jsx("img", {
    src: "".concat(footerLogo.url, "?fm=webp").concat(footerLogo.width > 400 ? '&w=400' : ''),
    alt: websiteName ? "".concat(websiteName, " Logo") : 'Logo',
    title: websiteName ? "".concat(websiteName, " Logo") : 'Logo',
    decoding: "async",
    loading: "lazy"
  })));
};
export default Logo;