var __jsx = React.createElement;
import React from 'react';
import SocialIcons from '../../../molecules/SocialIcons/SocialIcons';
var SocialMedia = function SocialMedia(_ref) {
  var socialMedia = _ref.socialMedia,
    siteName = _ref.siteName;
  return __jsx("div", {
    className: "nva-header__social"
  }, socialMedia && __jsx(SocialIcons, {
    locationName: siteName,
    socialIconsData: socialMedia
  }));
};
export default SocialMedia;