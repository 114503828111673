var __jsx = React.createElement;
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Anchor from '../../atoms/Anchor';
import List from '../List/List';
import ListItem from '../List/ListItem';
import analyticsConstants from '../../../constants/analyticsConstants';
import Dropdown from '../Dropdown';
import { HEADER_PARENTS } from '../../../constants/globalConstants';
var headerAnalytics = analyticsConstants.globalHeader;
var NavGroupItem = function NavGroupItem(_ref) {
  var _navItem$groupCollect;
  var navItem = _ref.navItem,
    openDropdownOnClick = _ref.openDropdownOnClick,
    menuType = _ref.menuType,
    parent = _ref.parent,
    index = _ref.index,
    totalItems = _ref.totalItems;
  var classes = {
    wrapper: 'nva-nav-list__group',
    button: 'nva-nav-list__item nva-nav-list__item--group u-button',
    dropdown: 'nva-nav-list__submenu'
  };
  var buttonStyleType;
  if (menuType === 'cta') {
    var isMobileMenuGrid = parent == HEADER_PARENTS.MOBILE_MENU_GRID;
    var isPrimaryCta = isMobileMenuGrid ? index === 0 : index + 1 === totalItems;
    buttonStyleType = isPrimaryCta ? 'primary-anchor' : 'secondary-anchor';
  }
  return __jsx(Dropdown, {
    classes: classes,
    label: navItem.keyCategoryName,
    buttonStyleType: buttonStyleType,
    icon: navItem.icon,
    arrowIcon: navItem.arrowIcon,
    type: menuType,
    openDropdownOnClick: openDropdownOnClick,
    analyticsType: headerAnalytics.analyticsType.GLOBAL_NAV,
    analyticsValue: headerAnalytics.analyticsValue.GLOBAL_NAV_CATEGORY_MENU
  }, __jsx(List, null, (_navItem$groupCollect = navItem.groupCollection.items) === null || _navItem$groupCollect === void 0 ? void 0 : _navItem$groupCollect.map(function (groupItems) {
    var _groupItems$groupItem;
    var submenuItems = (_groupItems$groupItem = groupItems.groupItemCollection) === null || _groupItems$groupItem === void 0 ? void 0 : _groupItems$groupItem.items;
    return submenuItems === null || submenuItems === void 0 ? void 0 : submenuItems.map(function (item) {
      return __jsx(Fragment, {
        key: item.sys.id
      }, item.__typename === 'ItemLabel' ? __jsx(ListItem, {
        className: "nva-nav-list__submenu-label u-button"
      }, item.itemName) :
      // __typename Link
      __jsx(ListItem, {
        className: "nva-nav-list__submenu-item"
      }, __jsx(Anchor, {
        title: item.label,
        "aria-label": item.ariaLabel,
        className: "nva-nav-list__submenu-link u-button",
        to: item.url,
        ctaBehavior: item.behavior,
        hyperlinkType: item.hyperlinkType,
        styleType: "secondary-link",
        "data-analytics-type": "sub-menu"
      }, item.label)));
    });
  })));
};
NavGroupItem.prototype = {
  navItem: PropTypes.object,
  openDropdownOnClick: PropTypes.bool,
  menuType: PropTypes.oneOf(['link', 'cta']),
  parent: PropTypes.oneOf(Object.values(HEADER_PARENTS)),
  index: PropTypes.number,
  totalItems: PropTypes.number
};
export default NavGroupItem;