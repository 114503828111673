var __jsx = React.createElement;
import React from 'react';
var HamburgerButton = function HamburgerButton(_ref) {
  var isMobileMenuOpen = _ref.isMobileMenuOpen,
    handleMobileMenuToggle = _ref.handleMobileMenuToggle;
  return __jsx("nav", {
    className: "nva-header__hamburger",
    "aria-label": "Header Navigation Menu"
  }, __jsx("button", {
    className: "nva-header__hamburger-button",
    "aria-label": "Open Navigation Menu",
    "aria-expanded": isMobileMenuOpen ? 'true' : 'false',
    onClick: handleMobileMenuToggle
  }, __jsx("svg", {
    viewBox: "0 0 24 24",
    width: "24",
    "aria-hidden": "true"
  }, __jsx("rect", {
    className: "nva-header__hamburger-line nva-header__hamburger-line--top",
    width: "24",
    height: "2",
    x: "0",
    y: "5",
    rx: "0",
    fill: "currentColor"
  }), __jsx("rect", {
    className: "nva-header__hamburger-line nva-header__hamburger-line--middle",
    width: "24",
    height: "2",
    x: "0",
    y: "11",
    rx: "0",
    fill: "currentColor"
  }), __jsx("rect", {
    className: "nva-header__hamburger-line nva-header__hamburger-line--bottom",
    width: "24",
    height: "2",
    x: "0",
    y: "17",
    rx: "0",
    fill: "currentColor"
  }))));
};
export default HamburgerButton;