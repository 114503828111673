import { css } from 'styled-components';
import { setCssVar } from '../../../utils';
import SEARCH_VAR from './Search.variables';
var width = SEARCH_VAR.width,
  padding = SEARCH_VAR.padding,
  borderRadius = SEARCH_VAR.borderRadius,
  borderWidth = SEARCH_VAR.borderWidth,
  borderColor = SEARCH_VAR.borderColor,
  textColor = SEARCH_VAR.textColor,
  backgroundColor = SEARCH_VAR.backgroundColor,
  submitPaddingInline = SEARCH_VAR.submitPaddingInline,
  submitIconColor = SEARCH_VAR.submitIconColor;
var styles = css(["&.nva-search{", "}.nva-search__input-wrapper{display:flex;position:relative;}.nva-search__input{", " ", " ", " ", " ", " ", " ", " width:var(", ");padding:var(", ");border-radius:var(", ");border:var(", ") solid var(", ");color:var(", ");background-color:var(", ");}.nva-search__submit{", " ", " position:absolute;left:0%;height:100%;background:none;padding-inline:var(", ");line-height:0;border:none;cursor:pointer;border-top-left-radius:var(", ");border-bottom-left-radius:var(", ");color:var(", ");}"], setCssVar(borderRadius, borderRadius.value), setCssVar(width, width.value), setCssVar(padding, padding.value), setCssVar(borderRadius, borderRadius.inherit), setCssVar(borderWidth, borderWidth.value), setCssVar(borderColor, borderColor.value), setCssVar(textColor, textColor.value), setCssVar(backgroundColor, backgroundColor.value), width["private"], padding["private"], borderRadius["private"], borderWidth["private"], borderColor["private"], textColor["private"], backgroundColor["private"], setCssVar(submitPaddingInline, submitPaddingInline.value), setCssVar(submitIconColor, submitIconColor.value), submitPaddingInline["private"], borderRadius["private"], borderRadius["private"], submitIconColor["private"]);
export default styles;