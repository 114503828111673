var __jsx = React.createElement;
import React from 'react';
import Search from '../../../atoms/Search';
var SearchArea = function SearchArea() {
  return __jsx("div", {
    className: "nva-header__search-area"
  }, __jsx(Search, {
    className: "nva-header__search-wrapper"
  }));
};
export default SearchArea;