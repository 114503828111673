import { css } from 'styled-components';
import breakPoints from '../../../themes/breakpoints';
import { getFooterTextColorStyle, getOverrideBackgroundColor } from '../../../utils/utilityFunction';
var backgroundStyles = '';
var styles = css(["", ";border-top:1px solid ", ";background-position:center;background-repeat:no-repeat;background-size:cover;width:100%;padding:30px 15px 15px;", " .variation-b{.copyright{margin-top:18px;ul{display:flex;align-items:center;li{margin-bottom:0;}}}.social-icons{display:none;@media (min-width:", "){display:block;}}}@media (min-width:", "){padding:30px 0 16px;}@media (min-width:", "){padding:30px 30px 40px;}@media (min-width:", "){padding:30px 30px 20px;}"], function (props) {
  var _props$theme, _props$theme$footer, _props$theme2, _props$globalFooterPr;
  backgroundStyles = getOverrideBackgroundColor(props === null || props === void 0 ? void 0 : (_props$theme = props.theme) === null || _props$theme === void 0 ? void 0 : (_props$theme$footer = _props$theme.footer) === null || _props$theme$footer === void 0 ? void 0 : _props$theme$footer.bgColor, props === null || props === void 0 ? void 0 : (_props$theme2 = props.theme) === null || _props$theme2 === void 0 ? void 0 : _props$theme2.palette, props === null || props === void 0 ? void 0 : (_props$globalFooterPr = props.globalFooterProps) === null || _props$globalFooterPr === void 0 ? void 0 : _props$globalFooterPr.overrideBackground, true);
  return backgroundStyles;
}, function (props) {
  var _props$theme3, _props$theme3$footer, _props$theme4, _props$globalFooterPr2;
  return props.theme.footer.seperatorColor ? "var(--sc-footer--seperator-color, ".concat(props.theme.footer.seperatorColor, ")") : getOverrideBackgroundColor(props === null || props === void 0 ? void 0 : (_props$theme3 = props.theme) === null || _props$theme3 === void 0 ? void 0 : (_props$theme3$footer = _props$theme3.footer) === null || _props$theme3$footer === void 0 ? void 0 : _props$theme3$footer.bgColor, props === null || props === void 0 ? void 0 : (_props$theme4 = props.theme) === null || _props$theme4 === void 0 ? void 0 : _props$theme4.palette, props === null || props === void 0 ? void 0 : (_props$globalFooterPr2 = props.globalFooterProps) === null || _props$globalFooterPr2 === void 0 ? void 0 : _props$globalFooterPr2.overrideBackground);
}, function (props) {
  var _props$globalFooterPr3;
  return props !== null && props !== void 0 && (_props$globalFooterPr3 = props.globalFooterProps) !== null && _props$globalFooterPr3 !== void 0 && _props$globalFooterPr3.fontColor ? "color: var(--sc-footer--text-color, ".concat(function (props) {
    var _props$globalFooterPr4, _props$theme5;
    return getFooterTextColorStyle(props === null || props === void 0 ? void 0 : (_props$globalFooterPr4 = props.globalFooterProps) === null || _props$globalFooterPr4 === void 0 ? void 0 : _props$globalFooterPr4.fontColor, props === null || props === void 0 ? void 0 : (_props$theme5 = props.theme) === null || _props$theme5 === void 0 ? void 0 : _props$theme5.footer, props.theme.footer.textColor);
  }, ");") : '';
}, breakPoints.desktop, breakPoints.mobileSm, breakPoints.desktop, breakPoints.desktopM);
export default styles;