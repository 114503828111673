var __jsx = React.createElement;
import React from 'react';
import { locationLabels } from '../../../../constants/labels/en';
import { parseWorkingHours } from '../../../../utils/utilityFunction';
import HoursOfOperation from '../../../molecules/HoursOfOperation';

// This is not really used as an AREA but in the dropdown of ContactInformation
var HoursOfOperationArea = function HoursOfOperationArea(_ref) {
  var yextContactInformationData = _ref.yextContactInformationData;
  var yextData = yextContactInformationData;
  var _parseWorkingHours = parseWorkingHours(yextData && yextData.hours, yextData && yextData.c_extendedHours),
    perDayHoursDetailArray = _parseWorkingHours.perDayHoursDetailArray,
    extendedHoursDetailArray = _parseWorkingHours.extendedHoursDetailArray,
    nameOfDay = _parseWorkingHours.nameOfDay;
  var hasExtendedHours = yextData && yextData.c_extendedHours;
  var hoursOfOperationObject = {
    ariaLabel: locationLabels.hoursofoperation,
    mainHoursText: locationLabels.mainhours,
    extraHoursText: locationLabels.extendedhours,
    extendedHours: hasExtendedHours,
    perDayHoursDetailArray: perDayHoursDetailArray,
    extendedHoursDetailArray: extendedHoursDetailArray,
    nameOfDay: nameOfDay,
    hoursTextLine: yextData && yextData.additionalHoursText,
    extendedHoursTextLine: yextData && yextData.c_extendedAdditionalHoursText
  };
  return __jsx(HoursOfOperation, hoursOfOperationObject);
};
export default HoursOfOperationArea;