var __jsx = React.createElement;
import React from 'react';
import NavMenu from '../../../molecules/NavList';
var MainMenu = function MainMenu(_ref) {
  var mainMenuCollection = _ref.mainMenuCollection,
    openDropdownOnClick = _ref.openDropdownOnClick;
  var mainMenu = mainMenuCollection === null || mainMenuCollection === void 0 ? void 0 : mainMenuCollection.items;
  return __jsx("nav", {
    className: "nva-footer__main-menu"
  }, __jsx(NavMenu, {
    className: "nva-footer__main-menu-list",
    menu: mainMenu,
    menuType: "link",
    parentType: "footer",
    openDropdownOnClick: openDropdownOnClick
  }));
};
export default MainMenu;